<template>
  <div>
    <v-form ref="removeFromParentModal">
      <v-card class="cr-modal">
        <v-card-title>
          <h2>Remove From {{ this.enterpriseAccount.name }}</h2>
        </v-card-title>
        <v-card-text>
          Are you sure you want to remove {{ this.teamAccount.name }} from
          {{ this.enterpriseAccount.name }}?
        </v-card-text>
        <v-card-actions>
          <v-layout justify-end class="mt-2">
            <div class="button-cont">
              <div style="margin-right: -1.5%">
                <v-btn
                  id="close-modal-button"
                  color="grey darken"
                  outline
                  @click="close"
                >
                  Cancel
                </v-btn>
                <v-btn id="save-button" class="delete-target" @click="confirm">
                  Confirm
                </v-btn>
              </div>
            </div>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import customerAccounts from '@/services/customerAccounts'
import { authComputed } from '@/state/helpers'

export default {
  props: {
    enterpriseAccount: { type: Object, default: () => {} },
    teamAccount: { type: Object, default: () => {} },
  },
  computed: {
    ...authComputed,
  },
  methods: {
    async confirm() {
      const payload = {
        parentCustomerAccountId: this.enterpriseAccount.customerAccountId,
        teamCustomerAccountId: this.teamAccount.customerAccountId,
      }

      await customerAccounts.removeTeamFromParentCustomerAccount(payload)

      this.close()

      this.$store.dispatch('app/showAlert', {
        message: 'Team account removed.',
      })
      this.$store.dispatch('app/dialogClosedAction')
    },
    close() {
      this.$store.dispatch('app/closeDialog')
    },
  },
}
</script>
<style lang="scss" scoped>
.dialog-container {
  display: flex;
  flex-direction: column;
  width: 600px;
  height: 500px;
  padding: 4% 8% 4% 8%;
  overflow-y: hidden;
  border-radius: 5px;

  .label-cont {
    display: flex;
    justify-content: space-between;
  }

  .description-input {
    height: 100%;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: $blue-light;
  }

  .button-cont {
    display: flex;
    justify-content: flex-end;
  }

  label {
    margin: 0;
    font-size: 20px;
    line-height: 1.42857;
  }

  .close {
    float: right;
    padding: 0;
    font-size: 21px;
    font-weight: 700;
    line-height: 1;
    color: $gray-base;
    text-shadow: 0 1px 0 $white;
    cursor: pointer;
    background: transparent;
    border: 0;
    opacity: 0.2;
  }

  .close:hover {
    opacity: 0.4;
  }
}

.description-input {
  height: 100%;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: $blue-light;
}

.delete-target {
  color: $white;
  background-color: $red !important;
}
</style>
